import React, { FC, useContext } from "react";
import { ShowMenuContext } from "../../layouts/Layout_LeftNav";
import { classNames } from "./utils";
import { IconV2 } from "@eco/stratos-components";
import { IconName } from "@fortawesome/fontawesome-common-types";
export interface VerticalNavLinkProps {
  label: string;
  href: string;
  external: boolean;
  current: boolean;
  icon?: IconName;
  children?: React.ReactNode;
}

export const VerticalNavLink: FC<VerticalNavLinkProps> = ({
  label,
  href,
  external,
  current,
  icon,
  children,
}) => {
  const { showMenu, setShowMenu } = useContext(ShowMenuContext);

  if (icon === undefined) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={href}
        target={external ? "_blank" : "_self"}
        rel={external ? "external" : "next"}
        data-stratos-nav-label={label}
        onClick={() => setShowMenu(!showMenu)}
        className={classNames(
          current
            ? "bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-100"
            : "",
          "block w-full rounded p-1 pl-10 text-sm font-light text-primaryText hover:bg-gray-100 hover:text-blue-700 dark:text-dark-primaryText dark:hover:bg-blue-900 dark:hover:text-blue-100",
        )}
      >
        {children}
      </a>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      target={external ? "_blank" : "_self"}
      rel={external ? "external" : "next"}
      data-stratos-nav-label={label}
      onClick={() => setShowMenu(!showMenu)}
      className="flex flex-row items-center rounded p-2 text-sm font-medium text-primaryText hover:bg-gray-100 hover:text-blue-700 dark:text-dark-primaryText dark:hover:bg-blue-900 dark:hover:text-blue-100"
    >
      <div className="mr-4">
        <IconV2
          type="fa"
          icon={{
            icon: ["far", icon],
            fixedWidth: true,
            size: "lg",
          }}
          color="blue"
        />
      </div>
      {label}
    </a>
  );
};
