import { NavigationItem } from "../../components/vertical-navigation/utils";

export const middlewareNav: NavigationItem[] = [
  {
    label: "Mapping",
    icon: "location-dot",
    href: "/build-with-eco/middleware/#global-services-mapping",
    active: false,
    external: false,
  },
  {
    label: "OneDTN",
    icon: "gear-complex-code",
    href: "/build-with-eco/middleware/#global-services-one-dtn",
    active: false,
    external: false,
  },
];
