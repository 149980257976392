/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useState, useRef, useEffect, createContext } from "react";
import { useRouter } from "next/router";
import { defaultNav } from "../navigation/default-nav";
import { VerticalNavigation } from "../components/vertical-navigation/VerticalNavigation";
import { NavigationItem } from "../components/vertical-navigation/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import MobileMenu from "../components/navbar/MobileMenu";
import NavBar from "../components/navbar/NavBar";
import Link from "next/link";
import { clearTimeout } from "timers";
import { Breadcrumbs, Footer } from "@eco/stratos-components";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

interface LeftNavType {
  toc?: boolean; // TRUE to show Table of Contents. FALSE to hide Table of Contents. Defaults to TRUE
  tocExtra?: React.ReactNode;
  nav?: NavigationItem[];
  wide?: boolean;
  breadcrumbTitle?: string;
  children?: React.ReactNode;
}

interface ShowMenuContextType {
  showMenu: boolean;
  setShowMenu: (e: boolean) => void;
}

export const ShowMenuContext = createContext<ShowMenuContextType>({
  showMenu: false,
  setShowMenu: () => {},
});

const Layout_LeftNav: FC<LeftNavType> = ({
  toc = true,
  tocExtra,
  nav,
  wide = false,
  breadcrumbTitle,
  ...props
}) => {
  const headingsContainerRef = useRef<HTMLDivElement>(null);
  const [pageHeadingNodes, setPageHeadingNodes] = useState<HeadingDataType[]>(
    [],
  );
  const [tocBuilding, setTocBuilding] = useState<boolean>(true);
  const router = useRouter();

  interface HeadingDataType {
    id: string;
    title: string;
    items?: HeadingDataType[];
  }

  // This builds out the AST using the heading tags to be used for the TOC in the right-hand column of the docs pages
  useEffect(() => {
    // not sure why, but even though useEffect will run after the DOM is rendered, I am unable to get
    // the h* tags from the DOM unless I break this out of the event loop, hence the `setTimeout`

    if (toc) {
      requestAnimationFrame(() => {
        const timeout = setTimeout(() => {
          const headingNodes =
            headingsContainerRef?.current?.querySelectorAll("h2, h3");

          setTocBuilding(true);
          const getNestedHeadings = (headingElements) => {
            const nestedHeadings: HeadingDataType[] = [];

            if (nestedHeadings.length === 0) {
              // eslint-disable-next-line no-console
              console.log(
                "Issue on Layout Left Nav for Nested Headings",
                nestedHeadings,
              );
            }

            headingElements.forEach((heading, index) => {
              const { innerText: title, id } = heading;
              if (heading.nodeName === "H2" && !id.includes("-omit-nav")) {
                nestedHeadings.push({ id, title, items: [] });
              } else if (
                heading.nodeName === "H3" &&
                nestedHeadings.length > 0 &&
                !id.includes("-omit-nav")
              ) {
                nestedHeadings[nestedHeadings.length - 1].items?.push({
                  id,
                  title,
                  items: [],
                });
              } else if (heading.nodeName === "H4") {
                // TODO: need to check for these lower level headings so we can style them
                // nestedHeadings[nestedHeadings.length - 1].items[
                //   nestedHeadings[nestedHeadings.length - 1].items.length - 1
                // ].items.push({
                //   id,
                //   title,
                // });
              }
            });
            return nestedHeadings;
          };

          const headings = getNestedHeadings(headingNodes);

          setPageHeadingNodes(headings);
          setTocBuilding(false);
        }, 300);

        return () => clearTimeout(timeout);
      });
    }

    // // Scroll to top of the content div on page load
    // if (pageTopRef?.current && window.location.hash === "") {
    //   pageTopRef.current.scrollIntoView({ block: "start", inline: "nearest" });
    // }
  }, [router.pathname]);

  // Feature flag to set showing either the Build with Eco Nav or the original nav
  const { ecoComponentsShowBuildWithEco011923 } = useFlags();
  let navigation = ecoComponentsShowBuildWithEco011923 ? nav : defaultNav;

  // width of main content area depending on if the left nav is visible

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const showLeftNav = navigation !== undefined;

  let breadcrumbs, sectionCrumb, sectionCrumbTitle;

  if (breadcrumbTitle) {
    switch (router.pathname.split("/")[2]) {
      case "user-interface":
        sectionCrumb = "/build-with-eco/user-interface/";
        sectionCrumbTitle = "User Interface & Front-End";
        break;
      case "global-services":
        sectionCrumb = "/build-with-eco/global-services/";
        sectionCrumbTitle = "Global Services";
        break;
      case "middleware":
        sectionCrumb = "/build-with-eco/middleware/";
        sectionCrumbTitle = "Middleware";
        break;
      case "back-end":
        sectionCrumb = "/build-with-eco/back-end/";
        sectionCrumbTitle = "Back-End & Infrastructure";
        break;
      default:
    }

    breadcrumbs = [
      {
        href: sectionCrumb,
        text: sectionCrumbTitle,
      },
      {
        href: router.pathname,
        text: breadcrumbTitle,
      },
    ];
  }

  return (
    <>
      <ShowMenuContext.Provider value={{ showMenu, setShowMenu }}>
        <div className="fixed z-[100] w-full">
          <NavBar showMenu={setShowMenu} />
          <MobileMenu
            sectionNav={navigation}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </div>
        <div className="flex h-screen flex-col justify-between">
          <main className="flex flex-row justify-center pt-[64px]">
            <div className="grid w-full max-w-screen-2xl grid-cols-12 gap-8 overflow-y-auto sm:px-6 lg:pl-8">
              {navigation !== undefined && (
                <div className="fixed z-0 hidden w-full max-w-screen-2xl grid-cols-12 flex-col gap-8 overflow-y-auto py-8 lg:grid">
                  {/* this runs through the component */}
                  <div className="col-span-3 flex flex-col">
                    <VerticalNavigation navigation={navigation} />
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  showLeftNav
                    ? "lg:col-start-4 lg:col-end-12"
                    : "lg:col-start-2 lg:col-end-12",
                  "justify-left z-10 col-span-12 px-4 sm:px-0",
                )}
                ref={headingsContainerRef}
              >
                <div
                  className={classNames(
                    showLeftNav ? "grid-cols-9" : "grid-cols-12",
                    "grid gap-8",
                  )}
                >
                  <div
                    className={classNames(
                      wide && showLeftNav ? "col-span-9 " : "",
                      wide && !showLeftNav ? "col-span-12" : "",
                      !wide && !showLeftNav ? "col-span-12 md:col-span-10" : "",
                      !wide && showLeftNav ? "col-span-9 md:col-span-7" : "",
                      "py-8",
                    )}
                  >
                    {breadcrumbs !== undefined && (
                      <div className="mb-3">
                        <Breadcrumbs customCrumbs={breadcrumbs} />
                      </div>
                    )}
                    {props.children}
                  </div>
                  {!wide && (
                    <div className="hidden py-8 md:col-span-2 md:block">
                      {toc && pageHeadingNodes && !tocBuilding && (
                        <div className="fixed mr-10 max-w-[13rem]">
                          <h5 className="mb-2 text-xs font-bold uppercase tracking-widest text-tertiary dark:text-dark-tertiary">
                            Contents
                          </h5>
                          <nav>
                            <ul>
                              {pageHeadingNodes.map((item, i) => (
                                <li
                                  key={i}
                                  className="group my-1 flex w-full flex-col items-start rounded-md text-xs font-medium text-primaryText hover:text-gray-900 dark:text-dark-primaryText dark:hover:text-gray-400"
                                >
                                  <Link href={`#${item.id}`}>{item.title}</Link>
                                  {item.items!.length > 0 && (
                                    <ul>
                                      {item.items!.map((child, j) => (
                                        <li
                                          key={j}
                                          className="group flex w-full flex-col items-start rounded-md pl-5 text-xs font-medium text-primaryText hover:text-gray-900 dark:text-dark-primaryText dark:hover:text-gray-400"
                                        >
                                          <Link href={`#${child.id}`}>
                                            {child.title}
                                          </Link>
                                          {child.items &&
                                            child.items.length > 0 && (
                                              <ul>
                                                {child.items.map(
                                                  (grandchild, k) => (
                                                    <li
                                                      key={k}
                                                      className="group flex w-full flex-col items-start rounded-md pl-7 text-xs font-medium text-primaryText hover:text-gray-900 dark:text-dark-primaryText dark:hover:text-gray-400"
                                                    >
                                                      <Link
                                                        href={`#${grandchild.id}`}
                                                      >
                                                        {grandchild.title}
                                                      </Link>
                                                    </li>
                                                  ),
                                                )}
                                              </ul>
                                            )}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </nav>
                          {tocExtra && <div className="mt-10">{tocExtra}</div>}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </ShowMenuContext.Provider>
    </>
  );
};

export default Layout_LeftNav;
