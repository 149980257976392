import React, { FC } from "react";
import { BaseProps } from "@eco/stratos-runtime";
import { NavigationItem } from "./utils";
import { VerticalNavItem } from "./VerticalNavItem";

export interface VerticalNavigationProps extends BaseProps {
  navigation: NavigationItem[];
}

export const VerticalNavigation: FC<VerticalNavigationProps> = ({
  id,
  testId,
  navigation,
}) => {
  return (
    <nav className="space-y-2" role="navigation">
      {navigation.map((item) => {
        return <VerticalNavItem item={item} key={item.label} />;
      })}
    </nav>
  );
};
