import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { ChildNavigationItem } from "../vertical-navigation/utils";

interface NavigationProps {
  headerNav: ChildNavigationItem[];
  classNames: (...classes: string[]) => string;
}

export const Navigation: FC<NavigationProps> = ({ headerNav, classNames }) => {
  const router = useRouter();

  return (
    <>
      {headerNav.map((item) => (
        <div
          key={item.label}
          className={classNames(
            router.pathname === item.href
              ? "border-text-link text-primaryText dark:border-dark-text-link dark:text-dark-primaryText"
              : "hover:border-dark-gray-300 border-transparent text-primaryText hover:border-gray-300 dark:text-dark-primaryText",
            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
          )}
        >
          <Link
            href={item.href!}
            passHref
            aria-current={router.pathname === item.href ? "page" : undefined}
            legacyBehavior
          >
            <a id={`nav-${item.label.replaceAll(" ", "-").toLowerCase()}`}>
              {item.label}
            </a>
          </Link>
        </div>
      ))}
    </>
  );
};

export default Navigation;
