import { ChildNavigationItem } from "../components/vertical-navigation/utils";

export const siteNav: ChildNavigationItem[] = [
  {
    label: "Docs",
    href: "/getting-started/overview",
    external: false,
  },
  {
    label: "Get Started",
    href: "/getting-started/installation",
    external: false,
  },
  {
    label: "FAQs",
    href: "/getting-started/faqs",
    external: false,
  },
];

export const buildWithEcoNav: ChildNavigationItem[] = [
  {
    label: "Getting Started",
    href: "/getting-started/overview",
    external: false,
  },
  {
    label: "Build with Eco",
    href: "/build-with-eco/user-interface",
    external: false,
  },
];
