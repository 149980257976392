/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, Fragment } from "react";
import { useTheme } from "next-themes";
import { IconV2 } from "@eco/stratos-components";
import { Menu, Transition } from "@headlessui/react";

interface LightDarkModeButtonProps {
  classNames: (...classes: string[]) => string;
}

interface Mode {
  value: string;
  label: string;
}

const modes: Mode[] = [
  {
    value: "light",
    label: "Light",
  },
  {
    value: "dark",
    label: "Dark",
  },
  {
    value: "system",
    label: "System",
  },
];

export const LightDarkModeButton: FC<LightDarkModeButtonProps> = ({
  classNames,
}) => {
  const { theme, systemTheme, setTheme } = useTheme();

  const toggleTheme = (mode: string) => {
    switch (mode) {
      case "dark":
        setTheme("dark");
        break;
      case "light":
        setTheme("light");
        break;
      default:
        setTheme("system");
    }
  };

  return (
    <Menu as="div" className="relative flex flex-col justify-center">
      <Menu.Button>
        <div className="text-gray-700 hover:text-gray-400 dark:text-gray-200 dark:hover:text-gray-400">
          {(theme === "light" ||
            (systemTheme === "light" && theme === "system")) && (
            <IconV2
              type="fa"
              icon={{
                icon: ["far", "sun-bright"],
                size: "lg",
                fixedWidth: true,
              }}
            />
          )}
          {(theme === "dark" ||
            (systemTheme === "dark" && theme === "system")) && (
            <IconV2
              type="fa"
              icon={{
                icon: ["far", "moon-stars"],
                size: "lg",
                fixedWidth: true,
              }}
            />
          )}
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-[10rem] w-48 origin-top-right rounded-md bg-white p-1 text-primaryText shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-dark-components-light dark:text-dark-primaryText">
          {modes.map((mode) => (
            <Menu.Item key={mode.value}>
              {({ active }) => (
                <button
                  className={classNames(
                    "block w-full rounded-md px-4 py-2 text-right text-base",
                    mode.value === theme ? "bg-gray-50 dark:bg-blue-950" : "",
                    active ? "w-full bg-gray-100 dark:bg-blue-900" : "",
                  )}
                  onClick={() => toggleTheme(mode.value)}
                >
                  {mode.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LightDarkModeButton;
