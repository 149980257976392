import React, { FC } from "react";
import { Icon } from "@eco/stratos-components";

interface MobileMenuButtonInterface {
  showMenu: (e: boolean) => void;
}

export const MobileMenuButton: FC<MobileMenuButtonInterface> = ({
  showMenu,
}) => {
  return (
    <button
      onClick={() => showMenu(true)}
      className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-700 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:text-gray-400 lg:hidden"
    >
      <Icon icon="fa-light fa-bars" />
    </button>
  );
};

export default MobileMenuButton;
