import React, { FC, Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IconV2 } from "@eco/stratos-components";
import { siteNav, buildWithEcoNav } from "../../navigation/site-nav";
import Link from "next/link";
import { useRouter } from "next/router";
import { VerticalNavItem } from "../vertical-navigation/VerticalNavItem";
import { NavigationItem } from "../../components/vertical-navigation/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { buildWithEcoNavigation } from "../build-with-eco/BuildWithEcoMenu";
import { IconName } from "@fortawesome/fontawesome-common-types";

interface MobileMenuInterface {
  sectionNav?: NavigationItem[];
  showMenu: boolean;
  setShowMenu: (e: boolean) => void;
  bweMenu?: boolean;
}

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const MobileMenu: FC<MobileMenuInterface> = ({
  sectionNav,
  showMenu,
  setShowMenu,
  bweMenu = false,
}) => {
  const router = useRouter();

  // Feature flag to set showing either the Build with Eco Nav or the original nav
  const { ecoComponentsShowBuildWithEco011923 } = useFlags();
  let navigation = ecoComponentsShowBuildWithEco011923
    ? buildWithEcoNav
    : siteNav;

  return (
    <Transition.Root show={showMenu} as={Fragment}>
      <Dialog as="div" className="lg:hidden" onClose={setShowMenu}>
        <div className="fixed inset-0 z-50 flex">
          {/* overlay */}
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="z-60 fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          {/* sidebar */}
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="z-70 relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
              {/* Close sidebar */}
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 -mr-12">
                  <button
                    type="button"
                    data-stratos-element="mobile-sidebar--close-button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-1 focus:ring-inset focus:ring-white"
                    onClick={() => setShowMenu(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <IconV2
                      type="fa"
                      icon={{
                        icon: ["far", "xmark"],
                        fixedWidth: true,
                        size: "xl",
                      }}
                      color="white"
                    />
                  </button>
                </div>
              </Transition.Child>
              {/* End Close sidebar */}

              <div className="">
                {/* Main site nav */}
                <div className="mb-4 space-y-1 border-b border-gray-200 pb-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.label}
                      href={item.href!}
                      aria-current={
                        router.pathname === item.href ? "page" : undefined
                      }
                      legacyBehavior
                    >
                      <span
                        className={classNames(
                          router.pathname === item.href
                            ? "border-blue-500 bg-blue-50 text-blue-700"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400",
                          "block border-l-4 py-2 pl-3 pr-4 text-base font-medium ",
                        )}
                      >
                        {item.label}
                      </span>
                    </Link>
                  ))}
                </div>

                {bweMenu && (
                  <div className="mb-4 border-b border-gray-200 pb-4 md:hidden md:border-0">
                    <nav
                      className="flex flex-col items-stretch gap-4 px-2 py-4"
                      role="navigation"
                    >
                      {buildWithEcoNavigation.map((item) => (
                        <div key={item.label}>
                          <Link
                            href={item.href}
                            passHref
                            aria-current={
                              router.pathname === item.href ? "page" : undefined
                            }
                            legacyBehavior
                          >
                            <a
                              id={`nav-${item.label
                                .replaceAll(" ", "-")
                                .toLowerCase()}`}
                              className={classNames(
                                router.pathname === item.href
                                  ? "bg-blue-50 text-blue-900 dark:text-gray-200"
                                  : "border-gray-200 text-gray-700 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400",
                                "align-center flex flex-row items-center gap-1 rounded-lg px-2 py-2 text-sm font-medium",
                              )}
                            >
                              <IconV2
                                type="fa"
                                icon={{
                                  icon: ["far", item.icon as IconName],
                                  fixedWidth: true,
                                }}
                                color="blue"
                              />
                              {item.label}
                            </a>
                          </Link>
                          {router.pathname === item.href && (
                            <div className="my-2 space-y-2">
                              {item.subnav.map((subnavItem) => {
                                return (
                                  <VerticalNavItem
                                    item={subnavItem}
                                    key={subnavItem.label}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ))}
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;
