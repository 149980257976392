import { NavigationItem } from "../../components/vertical-navigation/utils";

export const backEndNav: NavigationItem[] = [
  {
    label: "Starter Kits",
    icon: "hourglass-start",
    href: "/build-with-eco/back-end/#starter-kits",
    active: false,
    external: false,
  },
];
