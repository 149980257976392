import { useState, useEffect, FC, useRef } from "react";
import { IconV2, Button } from "@eco/stratos-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { Transition } from "@headlessui/react";
import { userInterfaceNav } from "../../navigation/build-with-eco/user-interface-nav";
import { globalServicesNav } from "../../navigation/build-with-eco/global-services-nav";
import { middlewareNav } from "../../navigation/build-with-eco/middleware-nav";
import { backEndNav } from "../../navigation/build-with-eco/back-end-nav";
import { IconName } from "@fortawesome/fontawesome-common-types";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

interface BuildWithEcoMenuProps {
  filter?: JSX.Element;
}

export const buildWithEcoNavigation = [
  {
    label: `User Interface \n& Front-End`,
    href: "/build-with-eco/user-interface",
    icon: "browsers",
    subnav: userInterfaceNav,
  },
  {
    label: "Global Services",
    href: "/build-with-eco/global-services",
    icon: "globe",
    subnav: globalServicesNav,
  },
  {
    label: "Middleware",
    href: "/build-with-eco/middleware",
    icon: "gear",
    subnav: middlewareNav,
  },
  {
    label: "Back-End \n& Infrastructure",
    href: "/build-with-eco/back-end",
    icon: "server",
    subnav: backEndNav,
  },
];

export const BuildWithEcoMenu: FC<BuildWithEcoMenuProps> = ({ filter }) => {
  const router = useRouter();
  const frontEndFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const [showFilter, setShowFilter] = useState(false);
  const handleClickOutside = (e) => {
    const buttonClicked = filterButtonRef.current?.contains(e.target);

    if (!buttonClicked && !frontEndFilterRef.current?.contains(e.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    setShowFilter(false);
  }, [router.pathname]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showFilter]);

  return (
    <>
      <div
        ref={frontEndFilterRef}
        className="flex flex-col items-stretch gap-4 px-2 py-4 sm:px-4 md:mx-auto md:grid md:max-w-screen-2xl md:grid-cols-4 md:items-end md:gap-6 lg:grid-cols-5 lg:px-8"
      >
        {buildWithEcoNavigation.map((item) => (
          <div key={item.label}>
            <Link
              href={item.href}
              passHref
              aria-current={router.pathname === item.href ? "page" : undefined}
              legacyBehavior
            >
              <a
                id={`nav-${item.label.replaceAll(" ", "-").toLowerCase()}`}
                className={classNames(
                  router.pathname === item.href
                    ? "bg-blue-50 text-blue-900 dark:bg-blue-800 dark:text-blue-100"
                    : "border-gray-200 text-primaryText hover:bg-gray-50 hover:text-gray-700 dark:border-gray-600 dark:text-dark-primaryText dark:hover:bg-gray-800 md:border",
                  "align-center flex flex-row items-center gap-1 rounded-lg px-2 py-2 text-sm font-medium md:h-14 md:gap-4 md:whitespace-pre-wrap md:px-3 md:py-0",
                )}
              >
                <IconV2
                  type="fa"
                  icon={{
                    icon: ["far", item.icon as IconName],
                    fixedWidth: true,
                    size: "lg",
                  }}
                  color="blue"
                />
                {item.label}
              </a>
            </Link>
          </div>
        ))}

        {filter && (
          <div className="col-span-4 flex md:justify-end lg:col-span-1">
            <Button
              variant="primary"
              iconName="sliders"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              Filter
            </Button>
          </div>
        )}
      </div>

      <div ref={filterButtonRef}>
        <Transition
          show={showFilter}
          enter="transition-[padding] duration-100 ease-out"
          enterFrom="py-0"
          enterTo="pt-2 pb-4"
          leave="transition-[padding] duration-100 ease-in"
          leaveFrom="pt-2 pb-4"
          leaveTo="py-0"
        >
          <div className="border-t px-2  pt-4 sm:px-4 md:mx-auto md:max-w-screen-2xl  lg:px-8">
            <Transition
              show={showFilter}
              enter="transition-scale duration-100 ease-out"
              enterFrom="scale-y-0"
              enterTo="scale-y-100"
            >
              {filter}
            </Transition>
          </div>
        </Transition>
      </div>
    </>
  );
};
