import React, { FC, Fragment, useEffect, useState } from "react";
import { NavigationItem, classNames } from "./utils";
import { Disclosure } from "@headlessui/react";
import { IconV2 } from "@eco/stratos-components";
import { VerticalNavItem } from "./VerticalNavItem";
import { useRouter } from "next/router";
import { IconName } from "@fortawesome/fontawesome-common-types";

export interface VerticalNavMenuProps {
  label: string;
  navItems: NavigationItem[];
  icon?: IconName;
  menuOpen?: boolean;
}

export const VerticalNavMenu: FC<VerticalNavMenuProps> = ({
  label,
  navItems,
  icon,
}) => {
  const [menuOpen, setMenuOpen] = useState(true);

  const getLabelFromRoute = (pathname: string) => {
    let sectionLabel = "";
    sectionLabel = pathname
      .split("/")[1]
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return sectionLabel;
  };

  const router = useRouter();
  const menuIsOpen = getLabelFromRoute(router.pathname) === label;

  return (
    <Disclosure
      as="div"
      id={`vertnav-${label.toLowerCase().replace(" ", "-")}`}
      defaultOpen={true}
    >
      {({ open, close }) => (
        <>
          <Disclosure.Button as={Fragment}>
            <button
              onClick={(e) => setMenuOpen(open)}
              className="flex w-full flex-row items-center justify-between rounded p-2 pl-4 text-sm font-medium text-primaryText hover:bg-gray-100 dark:text-dark-primaryText hover:dark:bg-gray-800 md:pl-2"
            >
              <div className="flex flex-row">
                {icon !== undefined && (
                  <div className="mr-4">
                    <IconV2
                      type="fa"
                      icon={{
                        icon: ["far", icon],
                        fixedWidth: true,
                      }}
                      color="blue"
                    />
                  </div>
                )}
                {label}
              </div>
              <div className="justify-self-end">
                <IconV2
                  type="fa"
                  icon={{
                    icon: ["far", "angle-right"],
                    fixedWidth: true,
                    rotation: open ? 90 : undefined,
                  }}
                  color="blue"
                />
              </div>
            </button>
          </Disclosure.Button>
          <Disclosure.Panel static={open}>
            <div className="mt-1 space-y-0.5">
              {navItems.map((subItem) => (
                <Disclosure.Button key={subItem.label} as="div">
                  <VerticalNavItem item={subItem}>
                    {subItem.label}
                  </VerticalNavItem>
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
