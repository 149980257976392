import { NavigationItem } from "../../components/vertical-navigation/utils";

export const globalServicesNav: NavigationItem[] = [
  // {
  //   label: "Portal",
  //   icon: "fa-light fa-person-to-portal",
  //   childNavigationItems: [],
  // },
  {
    label: "Security",
    icon: "shield-halved",
    childNavigationItems: [
      {
        label: "Application Security",
        href: "/build-with-eco/global-services/#global-services-security",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Identity & Access",
    icon: "user-lock",
    childNavigationItems: [
      {
        label: "Inputs",
        href: "/build-with-eco/global-services/#global-services-identity",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Analytics",
    icon: "chart-line",
    childNavigationItems: [
      {
        label: "Datadog",
        href: "/build-with-eco/global-services/#global-services-analytics",
        active: false,
        external: false,
      },
    ],
  },
];
