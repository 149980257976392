import { IconName } from "@fortawesome/fontawesome-common-types";

export interface ChildNavigationItem {
  href?: string;
  label: string;
  active?: boolean;
  external?: boolean;
  group?: boolean;
  icon?: IconName;
}
export interface ParentNavigationItem {
  label: string;
  icon?: IconName;
  childNavigationItems: ChildNavigationItem[];
}

export type NavigationItem = ChildNavigationItem | ParentNavigationItem;

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}
