import React, { FC } from "react";
import { NavigationItem } from "./utils";
import { VerticalNavMenu } from "./VerticalNavMenu";
import { VerticalNavLinkInternal } from "./VerticalNavLinkInternal";
import { VerticalNavLinkExternal } from "./VerticalNavLinkExternal";
import { VerticalNavGroup } from "./VerticalNavGroup";

export interface VerticalNavItemProps {
  item: NavigationItem;
  children?: React.ReactNode;
}

export const VerticalNavItem: FC<VerticalNavItemProps> = ({ item }) => {
  let external = "external" in item ? item.external ?? false : false;
  let group = "group" in item ? item.group ?? false : false;

  if ("href" in item && !external) {
    return (
      <VerticalNavLinkInternal
        href={item.href!}
        key={item.label}
        label={item.label}
        icon={item.icon}
      >
        <>{item.label}</>
      </VerticalNavLinkInternal>
    );
  } else if ("href" in item && external) {
    return (
      <VerticalNavLinkExternal
        href={item.href!}
        key={item.label}
        label={item.label}
        icon={item.icon}
      >
        <>{item.label}</>
      </VerticalNavLinkExternal>
    );
  } else if ("group" in item && group) {
    return <VerticalNavGroup label={item.label} />;
  } else if ("childNavigationItems" in item) {
    return (
      <VerticalNavMenu
        key={item.label}
        label={item.label}
        icon={item.icon}
        navItems={item.childNavigationItems}
      />
    );
  }

  return null;
};
