/**
 * This is menu item for the VerticalNav that has a slightly different look than the
 * clickable menu items. This does not have event handlers. it is only used for grouping
 * and separation within a vertical nav menu structure
 */
import React, { FC } from "react";

export interface VerticalNavGroupProps {
  label: string;
}

export const VerticalNavGroup: FC<VerticalNavGroupProps> = ({ label }) => {
  return (
    <span className="mt-4 block w-full rounded p-1 pl-10 text-xs font-bold uppercase text-gray-700">
      {label}
    </span>
  );
};
