import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import EcoLogo from "./EcoLogo";
import Navigation from "./Navigation";
import MobileMenuButton from "./MobileMenuButton";
import LightDarkModeButton from "./LightDarkModeButton";
import { NextConfig, NextPage } from "next";
import { useRouter } from "next/router";
import {
  IconV2 as IconComponent,
  Search as AlgoliaSearch,
} from "@eco/stratos-components";
import Link from "next/link";
import { siteNav, buildWithEcoNav } from "../../navigation/site-nav";
import GitlabLogo from "../GitlabLogo";
import getConfig from "next/config";
import { useFlags } from "launchdarkly-react-client-sdk";

const { publicRuntimeConfig } = getConfig() as NextConfig;

interface NavBarInterface {
  showMenu: (e: boolean) => void;
}

// https://tailwindui.com/components/application-ui/navigation/navbars
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const NavBar: FC<NavBarInterface> = ({ showMenu }) => {
  const router = useRouter();
  const isHome = router.route === "/";

  // Feature flag to set showing either the Build with Eco Nav or the original nav
  const { ecoComponentsShowBuildWithEco011923 } = useFlags();
  let navigation = (ecoComponentsShowBuildWithEco011923 as boolean)
    ? buildWithEcoNav
    : siteNav;

  // Logic for search modal
  const [isSearchActive, setIsSearchActive] = useState(false);

  // Logic to listen if search item was clicked, need to close search modal
  useEffect(() => {
    const handleRouteChange = () => {
      setIsSearchActive(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const algoliaAppID = publicRuntimeConfig?.ALGOLIA_APP_ID as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const algoliaApiKey = publicRuntimeConfig?.ALGOLIA_API_KEY as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const algoliaIdxName = publicRuntimeConfig?.ALGOLIA_INDEX_NAME as string;

  return (
    <>
      <div className="min-h-full">
        <AlgoliaSearch
          appID={algoliaAppID}
          apiKey={algoliaApiKey}
          indexName={algoliaIdxName}
          isSearchActive={isSearchActive}
          setIsSearchActive={setIsSearchActive}
        />

        <nav className="left-0 right-0 top-0 z-sticky border-b border-light bg-white dark:border-dark-light dark:bg-dark-container-light">
          <div className="mx-auto max-w-screen-2xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <EcoLogo />
                </div>

                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  <Navigation headerNav={navigation} classNames={classNames} />
                </div>
              </div>

              {/* left end utility buttons / features */}
              <div className="flex flex-row items-center justify-end gap-4">
                {/* Placeholder for Search */}
                <div className="mt-1 flex flex-1 items-center justify-center text-primaryText dark:text-dark-primaryText lg:ml-6">
                  <button
                    onClick={() =>
                      setIsSearchActive((prevState: boolean) => !prevState)
                    }
                  >
                    <IconComponent
                      type="fa"
                      icon={{
                        icon: ["far", "magnifying-glass"],
                        fixedWidth: true,
                        size: "lg",
                      }}
                    />
                  </button>
                </div>

                <Link
                  passHref
                  href="https://gitlab.com/dtnse/kubernetes/eco/dtn-eco-design-system"
                  legacyBehavior
                >
                  <span className="block h-5 w-5 fill-text-link hover:fill-text-link-hover dark:fill-dark-text-link dark:hover:fill-dark-text-link-hover">
                    <GitlabLogo />
                  </span>
                </Link>

                <LightDarkModeButton classNames={classNames} />

                {/* Mobile menu button */}
                <MobileMenuButton showMenu={showMenu} />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
