import React, { FC } from "react";
import { VerticalNavLink } from "./VerticalNavLink";
import { IconName } from "@fortawesome/fontawesome-common-types";

export interface VerticalNavLinkExternalProps {
  label: string;
  href: string;
  icon?: IconName;
  children?: React.ReactElement;
}

export const VerticalNavLinkExternal: FC<VerticalNavLinkExternalProps> = ({
  label,
  href,
  icon,
  children,
}) => {
  return (
    <VerticalNavLink
      icon={icon}
      label={label}
      href={href}
      external={true}
      current={false}
    >
      {children}
    </VerticalNavLink>
  );
};
