import Link from "next/link";
import React, { FC } from "react";

export const EcoLogo: FC = () => {
  return (
    <>
      <Link href="/" legacyBehavior>
        <svg
          className="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="50.619"
          height="22"
          viewBox="0 0 50.619 22"
        >
          <g transform="translate(34.732 5.851)">
            <path
              style={{
                fill: "#62bb46",
              }}
              d="M581.974,232.818a3.357,3.357,0,0,1-3.329-3.384H574.03a7.945,7.945,0,1,0,15.887,0H585.3a3.357,3.357,0,0,1-3.329,3.384"
              transform="translate(-574.03 -221.36)"
            ></path>

            <path
              style={{
                fill: "#008fd5",
              }}
              d="M581.974,197.545a8.009,8.009,0,0,0-7.943,8.074h4.615a3.329,3.329,0,1,1,6.658,0h4.615a8.009,8.009,0,0,0-7.944-8.074"
              transform="translate(-574.03 -197.545)"
            ></path>
          </g>

          <g
            style={{
              isolation: "isolate",
            }}
          >
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path
                className="fill-[#030504] dark:fill-white"
                d="M296.059,433.453H279.444v-21.7H296v4.666H284.621v3.75H295.33v4.65H284.621v3.969h11.438Z"
                transform="translate(-279.444 -411.753)"
              ></path>
              <path
                id="here"
                className="fill-[#030504] dark:fill-white"
                d="M308.7,419.788l-3.8,2.649a3.339,3.339,0,0,0-2.805-1.455,3.509,3.509,0,0,0-.03,6.973,3.547,3.547,0,0,0,2.867-1.472l3.781,2.7a7.686,7.686,0,0,1-6.51,3.3c-4.85,0-8.2-3.362-8.2-8.012a7.781,7.781,0,0,1,8.2-8.013A7.644,7.644,0,0,1,308.7,419.788Z"
                transform="translate(-275.186 -410.508)"
              ></path>
            </g>
          </g>
        </svg>
      </Link>

      <span></span>
    </>
  );
};

export default EcoLogo;
