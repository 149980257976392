import { NavigationItem } from "../../components/vertical-navigation/utils";

export const userInterfaceNav: NavigationItem[] = [
  {
    label: "General",
    icon: "cubes",
    childNavigationItems: [
      {
        label: "Inputs & Forms",
        href: "/build-with-eco/user-interface/#inputs-forms",
        active: false,
        external: false,
      },
      {
        label: "Setup Assistance",
        href: "/build-with-eco/user-interface/#setup-assistance",
        active: false,
        external: false,
      },
      {
        label: "Typography",
        href: "/build-with-eco/user-interface/#typography",
        active: false,
        external: false,
      },
      {
        label: "Feedback",
        href: "/build-with-eco/user-interface/#feedback",
        active: false,
        external: false,
      },
      {
        label: "Data Display",
        href: "/build-with-eco/user-interface/#data-display",
        active: false,
        external: false,
      },
      {
        label: "Containment",
        href: "/build-with-eco/user-interface/#containment",
        active: false,
        external: false,
      },
      {
        label: "Navigation",
        href: "/build-with-eco/user-interface/#navigation",
        active: false,
        external: false,
      },
      {
        label: "Mapping",
        href: "/build-with-eco/user-interface/#mapping",
        active: false,
        external: false,
      },
      {
        label: "Charting",
        href: "/build-with-eco/user-interface/#charting",
        active: false,
        external: false,
      },
      {
        label: "Landing Page & Dashboards",
        href: "/build-with-eco/user-interface/#dashboards",
        active: false,
        external: false,
      },
      {
        label: "Elements",
        href: "/build-with-eco/user-interface/#elements",
        active: false,
        external: false,
      },
      {
        label: "Eco Framework",
        href: "/build-with-eco/user-interface/#eco-framework",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Specialized",
    icon: "sun-cloud",
    childNavigationItems: [
      {
        label: "Forecasting",
        href: "/build-with-eco/user-interface/#forecasting",
        active: false,
        external: false,
      },
      {
        label: "Date & Time",
        href: "/build-with-eco/user-interface/#date-time",
        active: false,
        external: false,
      },
    ],
  },
];
