import React, { FC, cloneElement } from "react";
import Link from "next/link";
import { VerticalNavLink } from "./VerticalNavLink";
import { useRouter } from "next/router";
import { IconName } from "@fortawesome/fontawesome-common-types";

export interface VerticalNavLinkInternalProps {
  label: string;
  href: string;
  icon?: IconName;
  children?: React.ReactElement;
}

export const VerticalNavLinkInternal: FC<VerticalNavLinkInternalProps> = ({
  label,
  href = "#",
  icon,
  children,
}) => {
  const router = useRouter();
  let { pathname } = router;
  let current = href === pathname.split("/", 3).join("/");

  return (
    <Link href={href} passHref legacyBehavior>
      <div key={label}>
        <VerticalNavLink
          label={label}
          href={href}
          current={current}
          external={false}
          icon={icon}
        >
          {children}
        </VerticalNavLink>
      </div>
    </Link>
  );
};
