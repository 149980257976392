import { NavigationItem } from "../components/vertical-navigation/utils";

export const defaultNav: NavigationItem[] = [
  {
    label: "Getting Started",
    icon: "browser",
    childNavigationItems: [
      {
        label: "Overview",
        href: "/getting-started/overview",
        active: false,
        external: false,
      },
      {
        label: "Installation",
        href: "/getting-started/installation",
        active: false,
        external: false,
      },
      {
        label: "Applications",
        href: "/getting-started/applications",
        active: false,
        external: false,
      },
      {
        label: "Examples",
        href: "/getting-started/examples",
        active: false,
        external: false,
      },
      {
        label: "Eco Portal Installation",
        href: "/getting-started/eco-portal",
        active: false,
        external: false,
      },
      {
        label: "Learn",
        href: "/getting-started/learn",
        active: false,
        external: false,
      },
      {
        label: "FAQs",
        href: "/getting-started/faqs",
        active: false,
        external: false,
      },
      {
        label: "Supported Platforms",
        href: "/getting-started/supported-platforms",
        active: false,
        external: false,
      },
      {
        label: "OSS and Contribution",
        href: "/getting-started/oss-contribution",
        active: false,
        external: false,
      },
      {
        label: "Glossary",
        href: "/getting-started/glossary",
        active: false,
        external: false,
      },
      {
        label: "Releases",
        href: "/getting-started/releases",
        active: false,
        external: false,
      },
      {
        label: "Help",
        href: "/getting-started/help",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Components",
    icon: "cube",
    childNavigationItems: [
      {
        label: "Inputs",
        group: true,
      },
      {
        label: "AdvancedDatePicker",
        href: "/components/elements/advanced-datepicker",
        active: false,
        external: false,
      },
      {
        label: "Autocomplete",
        href: "/components/elements/autocomplete",
        active: false,
        external: false,
      },
      {
        label: "Button",
        href: "/components/elements/button",
        active: false,
        external: false,
      },
      {
        label: "ButtonGroup",
        href: "/components/elements/buttongroup",
        active: false,
        external: false,
      },
      {
        label: "Checkbox",
        href: "/components/elements/checkbox",
        active: false,
        external: false,
      },
      {
        label: "Datepicker",
        href: "/components/elements/datepicker",
        active: false,
        external: false,
      },

      {
        label: "Field",
        href: "/components/elements/field",
        active: false,
        external: false,
      },
      {
        label: "Icon",
        href: "/components/elements/icon",
        active: false,
        external: false,
      },
      {
        label: "Input",
        href: "/components/elements/input",
        active: false,
        external: false,
      },
      {
        label: "Number Stepper",
        href: "/components/elements/number-stepper",
        active: false,
        external: false,
      },
      {
        label: "Textarea",
        href: "/components/elements/textarea",
        active: false,
        external: false,
      },
      {
        label: "RadioGroup",
        href: "/components/elements/radiogroup",
        active: false,
        external: false,
      },
      {
        label: "Pill",
        href: "/components/elements/pill",
        active: false,
        external: false,
      },
      {
        label: "Search Bar",
        href: "/components/elements/search-bar",
        active: false,
        external: false,
      },
      {
        label: "Select",
        href: "/components/elements/select",
        active: false,
        external: false,
      },
      {
        label: "Timepicker",
        href: "/components/elements/timepicker",
        active: false,
        external: false,
      },
      {
        label: "Toggle",
        href: "/components/elements/toggle",
        active: false,
        external: false,
      },
      {
        label: "Elements",
        group: true,
      },
      {
        label: "Avatar",
        href: "/components/elements/avatar",
        active: false,
        external: false,
      },
      {
        label: "File Upload",
        href: "/components/elements/fileupload",
        active: false,
        external: false,
      },
      {
        label: "Tooltip",
        href: "/components/elements/tooltip",
        active: false,
        external: false,
      },
      {
        label: "Data Display",
        group: true,
      },
      {
        label: "Card",
        href: "/components/elements/card",
        active: false,
        external: false,
      },
      {
        label: "DataTable (react-table v7)",
        href: "/components/templates/datatable",
        active: false,
        external: false,
      },
      {
        label: "DataTable (react-table v8)",
        href: "/components/templates/datatablev8",
        active: false,
        external: false,
      },
      {
        label: "Description List",
        href: "/components/elements/description-list",
        active: false,
        external: false,
      },
      {
        label: "Stacked List",
        href: "/components/templates/stacked-list",
        active: false,
        external: false,
      },
      {
        label: "Table",
        href: "/components/templates/table",
        active: false,
        external: false,
      },

      {
        label: "Service",
        group: true,
      },
      {
        label: "Map Core",
        href: "/applications/map",
        active: false,
        external: false,
      },
      {
        label: "Feedback",
        group: true,
      },
      {
        label: "API Error",
        href: "/components/feedback/apiError",
        active: false,
        external: false,
      },
      {
        label: "Alerts",
        href: "/components/feedback/alert",
        active: false,
        external: false,
      },
      {
        label: "Modal",
        href: "/components/feedback/modal",
        active: false,
        external: false,
      },
      {
        label: "Snackbar",
        href: "/components/feedback/snackbar",
        active: false,
        external: false,
      },
      {
        label: "Spinner",
        href: "/components/feedback/spinner",
        active: false,
        external: false,
      },
      {
        label: "Status Indicator",
        href: "/components/feedback/status-indicator",
        active: false,
        external: false,
      },
      {
        label: "Organization",
        group: true,
      },
      {
        label: "Disclosure",
        href: "/components/templates/disclosure",
        active: false,
        external: false,
      },
      {
        label: "Drawer",
        href: "/components/templates/drawer",
        active: false,
        external: false,
      },
      {
        label: "Grid",
        href: "/components/templates/grids",
        active: false,
        external: false,
      },
      {
        label: "Navigation",
        group: true,
      },
      {
        label: "Menu",
        href: "/components/navigation/menu",
        active: false,
        external: false,
      },
      {
        label: "Pagination",
        href: "/components/navigation/pagination",
        active: false,
        external: false,
      },
      {
        label: "Tabs",
        href: "/components/navigation/tabs",
        active: false,
        external: false,
      },
      {
        label: "Wizard",
        href: "/components/elements/wizard",
        active: false,
        external: false,
      },
      {
        label: "Typography",
        group: true,
      },
      {
        label: "Code",
        href: "/components/typography/code",
        active: false,
        external: false,
      },
      {
        label: "Heading",
        href: "/components/typography/heading",
        active: false,
        external: false,
      },
      {
        label: "Paragraph",
        href: "/components/typography/paragraph",
        active: false,
        external: false,
      },
      {
        label: "Prose",
        href: "/components/typography/prose",
        active: false,
        external: false,
      },
      {
        label: "Layouts",
        group: true,
      },
      {
        label: "Page Shell",
        href: "/components/layouts/page-shell",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Widgets",
    icon: "cubes",
    childNavigationItems: [
      {
        label: "Countdown Timer",
        href: "/widgets/countdown-timer",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "Customization",
    icon: "gear",
    childNavigationItems: [
      {
        label: "Typography",
        href: "/components/typography/prose",
        active: false,
        external: false,
      },
    ],
  },
  {
    label: "3rd Party",
    icon: "grid-2-plus",
    childNavigationItems: [
      {
        label: "Analytics",
        href: "/getting-started/examples/analytics/",
        active: false,
        external: false,
      },
      {
        label: "Feature Flags",
        href: "/getting-started/examples/launch-darkly/",
        active: false,
        external: false,
      },
      {
        label: "Authentication",
        href: "/getting-started/examples/authentication/",
        active: false,
        external: false,
      },
      {
        label: "Search",
        href: "/getting-started/examples/search-algolia/",
        active: false,
        external: false,
      },
      {
        label: "Charting",
        href: "/3rd-party/charting",
        active: false,
        external: false,
      },
    ],
  },
];
